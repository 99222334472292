import aeLogo from '../assets/logo-ae.svg';
export function Footer() {
  return (
    <footer className="flex flex-row items-center justify-center gap-4 p-2 my-10 md:mt-20 md:mb-20 text-white">
      <p className="text-sm text-white">Made with ❤️ by</p>{' '}
      <a
        href="https://ae.studio?utm_source=layovercheck.com"
        target="_black"
        rel="norefferer"
      >
        <img className="inline-block" src={aeLogo} alt="AE Studio" />
      </a>
    </footer>
  );
}
