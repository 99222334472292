import React from 'react';
import logoImage from '../assets/logo-layover-check.svg';

export const Logo = () => {
  return (
    <div className="mt-2">
      <img className="mx-auto" src={logoImage} alt="Layover Check" />
    </div>
  );
};
