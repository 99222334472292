import { PricingPage as SDSPricingPage } from 'sds-projects';
import { Footer } from './components/Footer';
import { Logo } from './components/logo';

const features = ['Unlimited Layover checks', 'Stay on time', 'Free forever'];

export function PricingPage() {
  return (
    <div className="m-auto text-center text-gray-300 pb-10">
      <Logo />
      <h2 className="mt-5 font-semibold text-3xl text-white">
        Simple pricing, no commitment
      </h2>
      <div className="my-[-80px]">
        <SDSPricingPage
          customTheme={{
            darkLogo: true,
            colors: {
              text: 'text-white',
              primaryButton:
                'text-white bg-blue-600 border border-blue-300 hover:opacity-80 hover:bg-black/20',
              secondaryButton:
                'cursor-not-allowed text-blue-300/90 border border-blue-300/90',
              icon: 'text-blue-500',
              primaryCard: 'flex-1 bg-black/40 border-2 border-blue-200',
              secondaryCard:
                'flex-1 bg-black/20 border-2 border-blue-200 lg:border-r-0',
              badge: 'text-white bg-blue-300/20',
            },
          }}
          freeTierFeatures={features}
        />
      </div>
      <Footer />
    </div>
  );
}
