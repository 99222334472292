import React, { useState } from 'react';
import { checkLayoverTime } from '../api/gpt';
import audio from '../assets/slap.mp3';
import { Logo } from './logo';

export function LayoverCheck({ setIsEnoughTime, setOpen, setResponse }) {
  const [layoverTime, setLayoverTime] = useState('');
  const [airport, setAirport] = useState('');
  const [travellingWithFamily, setTravellingWithFamily] = useState(false);
  const [isInt, setIsInt] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();

    // Call GPT API here to get YES or NO
    // For now, let's assume the API response is stored in a variable called response
    const response = await checkLayoverTime(
      layoverTime,
      airport,
      travellingWithFamily,
      isInt,
    );

    if (response !== 'YES') {
      new Audio(audio).play();
    }

    setResponse(response);
    setIsEnoughTime(response === 'YES');
    setOpen(true);
    setIsSubmitting(false);
  };

  return (
    <div className="h-auto sm:h-[80%] flex items-center">
      <div className="px-8 mx-auto w-full sm:max-w-[480px] md:max-w-[720px] lg:max-w-[1024px]">
        <Logo />
        <div className="mt-16 bg-white px-6 py-12 border-blue-50 sm:border-2 rounded-lg sm:px-12">
          <form className="w-full gap-8 md:flex" onSubmit={handleSubmit}>
            <div className="grow">
              <div className="grow md:flex gap-8">
                <div className="grow">
                  <label
                    htmlFor="layoverTime"
                    className="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Layover Time (in minutes)
                  </label>
                  <div className="mt-2">
                    <input
                      className="block w-full rounded-full border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="number"
                      name="layoverTime"
                      value={layoverTime}
                      onChange={(e) => setLayoverTime(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-2 md:mt-0 grow">
                  <label
                    htmlFor="airport"
                    className="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Airport Code
                  </label>
                  <div className="mt-2">
                    <input
                      className="block w-full rounded-full border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      type="text"
                      name="airport"
                      value={airport}
                      onChange={(e) => setAirport(e.target.value)}
                      maxLength="3"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="grow md:flex gap-8">
                <div className="mt-2 flex items-center">
                  <input
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    type="checkbox"
                    id="travellingWithFamily"
                    name="travellingWithFamily"
                    checked={travellingWithFamily}
                    onChange={(e) => setTravellingWithFamily(e.target.checked)}
                  />
                  <label
                    htmlFor="travellingWithFamily"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Traveling with family or part of a group
                  </label>
                </div>
                <div className="mt-2 flex items-center">
                  <input
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    type="checkbox"
                    id="isInt"
                    name="isInt"
                    checked={isInt}
                    onChange={(e) => setIsInt(e.target.checked)}
                  />
                  <label
                    htmlFor="isInt"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    International flight
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-2 md:mt-8 grow">
              <button
                id="submitButton"
                className="flex w-full justify-center rounded-full bg-blue-600 py-3 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                type="submit"
                disabled={isSubmitting}
              >
                Check Your Layover
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
