import {
  BuildingOfficeIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SdsNavbar } from 'sds-projects';
import './App.css';
import { HomePage } from './HomePage';
import { PricingPage } from './PricingPage';
import { WhoMadeThisPage } from './WhoMadeThisPage';

const navigation = [
  { name: 'Home', page: '/', icon: HomeIcon },
  { name: 'Pricing', page: '/pricing', icon: CurrencyDollarIcon },
  {
    name: 'Who made this?',
    page: '/who-made-this',
    icon: BuildingOfficeIcon,
  },
];

const navbarTheme = {
  darkLogo: true,
  colors: {
    background: 'bg-transparent',
    border: 'border-none',
    tabs: 'text-white hover:bg-white/20',
    hover: 'hover:bg-white/20',
    dropdown:
      'bg-[#2493e6] text-white font-normal ring-white hover:bg-white/20',
    dropdownItem: 'bg-[#2493e6] divide-white ring-white border border-white',
    dropdownText: 'text-white hover:bg-white/20',
  },
};

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/pricing',
      element: <PricingPage />,
    },
    {
      path: '/who-made-this',
      element: <WhoMadeThisPage />,
    },
  ]);

  return (
    <SdsNavbar
      projectName="Snap Ingredients"
      customTheme={navbarTheme}
      navigation={navigation}
      hideUserMenu
    >
      <RouterProvider router={router} />
    </SdsNavbar>
  );
};

export default App;
