import PropTypes from 'prop-types';
import { React } from 'react';

const Result = ({ message }) => {
  return <p className="text-center">{message}</p>;
};

Result.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Result;
