import React from 'react';
import ohYesGif from '../assets/ohyes.gif';

const OhYes = () => {
  return (
    <div className="OhYes mt-2">
      <img className="mx-auto" src={ohYesGif} alt="Oh Yes GIF" />
    </div>
  );
};

export default OhYes;
