import React from 'react';
import ohNoGif from '../assets/ohno.gif';

const OhNo = () => {
  return (
    <div className="OhYes mt-2">
      <img className="mx-auto"  src={ohNoGif} alt="Oh Noo GIF" />
    </div>
  );
};

export default OhNo;
