import OhNo from './OhNo';
import OhYes from './OhYes';
import Result from './Result';

export function Modal({ open, onClose, success, response }) {
  if (!open) {
    return null;
  }

  const message = success
    ? 'There is a high probability that this layover will give you enough time to board your connecting flight.'
    : 'Unfortunately, you probably need to find a new flight.';

  return (
    <>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
        &nbsp;
      </div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="result flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <Result message={message} />
            {success ? <OhYes /> : <OhNo />}
            {!success && <p className="mt-2">{response.replace('NO. ', '')}</p>}
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="flex w-full justify-center rounded-full bg-blue-600 py-3 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={onClose}
              >
                Check again
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
