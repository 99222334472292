import axios from 'axios';

const GPT_API_URL = 'https://api.openai.com/v1/chat/completions';
const GPT_API_KEY = process.env.REACT_APP_GPT_API_KEY;

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${GPT_API_KEY}`,
};

export const checkLayoverTime = async (
  layoverTime,
  airport,
  isFamily,
  isInt,
) => {
  try {
    const prompt = `Given a layover time of ${layoverTime} minute(s) at ${airport} airport and traveling ${
      isFamily ? 'with family or a group' : 'alone'
    } ${
      isInt ? 'on an international itinerary' : ''
    }, is there enough time to board a connecting flight? Please consider potential delays and going through security.
    Provide only YES or NO answers. If the answer is NO, explain why and who many time is necessary.`;

    const maxTokens = 1250;

    const response = await axios.post(
      GPT_API_URL,
      {
        model: 'gpt-4',
        messages: [{ role: 'user', content: prompt }],
        max_tokens: maxTokens,
      },
      { headers },
    );

    const { data } = response;

    const answer = data.choices[0].message.content;

    return answer;
  } catch (error) {
    console.error('Error checking layover time:', error);
    return false;
  }
};
