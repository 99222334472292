import React, { useState } from 'react';
import './App.css';
import { Footer } from './components/Footer';
import { LayoverCheck } from './components/LayoverCheck';
import { Modal } from './components/Modal';

export function HomePage() {
  const [response, setResponse] = useState('');
  const [isEnoughTime, setIsEnoughTime] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className="app h-[93vh] flex flex-col justify-between">
      <LayoverCheck
        setIsEnoughTime={setIsEnoughTime}
        setOpen={setOpen}
        setResponse={setResponse}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        success={isEnoughTime}
        response={response}
      />

      <Footer />
    </div>
  );
}
